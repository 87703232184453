import { useEffect, useState, useRef } from 'react';
import logo from './logo.png';
import TanzilDewan from './Tanzil-home.png';
import TanzilDewancv from './Tanzil-Cv.pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faWhatsapp, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faBarChart } from '@fortawesome/free-regular-svg-icons';

import ReviewSlider from './CustomSlider';
import './App.css';
import axios from './axiosConfig';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import AgeCalculator from './AgeCalculator';

function App() {
  const [progressHtml, setprogressHtml] = useState(0)
  const [progressJs, setprogressJs] = useState(0)
  const [progressPhp, setprogressPhp] = useState(0)
  const [progressMysql, setprogressMysql] = useState(0)
  const [progressWordpress, setprogressWordpress] = useState(0)
  const [progressWordpressd, setprogressWordpressd] = useState(0)
  const [ContactName, setContactName] = useState('')
  const [ContactEmail, setContactEmail] = useState('')
  const [ContactMessage, setContactMessage] = useState('')
  const [msgSent, setmsgSent] = useState('')
  const [mailstatus, setmailstatus] = useState('')

  const Yearofexpericen = "2018-03-25T00:00:00Z";

  const canvasRef = useRef(null);


  // ... (previous state variables)
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState('load');

  useEffect(() => {

    setTimeout(() => {
      setprogressHtml(progressHtml + 100)
      setprogressJs(progressJs + 97)
      setprogressPhp(progressPhp + 98)
      setprogressMysql(progressMysql + 98)
      setprogressWordpress(progressWordpress + 100)
      setprogressWordpressd(progressWordpressd + 100)

    }, 100);


  }, [])


  const activeTab = (e) => {
    e.target.classList.add("active");
    document.querySelectorAll("section.profolio nav.listofctg li").forEach((e) => {
      e.classList.remove("active");
    });

    const activeOnTop = e.target.dataset.active;
    console.log(document.querySelectorAll(".products"))
    document.querySelectorAll(".products").forEach((e) => {
      e.classList.remove("active");
      if (e.dataset.active == activeOnTop) {
        e.classList.add("active");
      }


    });





  }
  const activeContact = (e) => {

    const activeOnTop = e.target.dataset.active;

    document.querySelectorAll(".staps").forEach((e) => {
      e.classList.remove("active");
      if(e.dataset.active == 'step3')
      {
        if (validateForm()) {
          if (e.dataset.active == activeOnTop) {
            e.classList.add("active");
          }
        }else{
          return;
        }
      }
      
      if (e.dataset.active == activeOnTop) {
        e.classList.add("active");
      }


    });



  }


  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    // Name validation
    if (!ContactName.trim()) {
      formIsValid = false;
      errors["name"] = "Name is required";
    } else if (!/^[a-zA-Z ]{2,30}$/.test(ContactName)) {
      formIsValid = false;
      errors["name"] = "Name should only contain letters and be 2-30 characters long";
    }

    // Email validation
    if (!ContactEmail.trim()) {
      formIsValid = false;
      errors["email"] = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(ContactEmail)) {
      formIsValid = false;
      errors["email"] = "Email is invalid";
    }

    // Message validation
    if (!ContactMessage.trim()) {
      formIsValid = false;
      errors["message"] = "Message is required";
    } else if (ContactMessage.length < 10) {
      formIsValid = false;
      errors["message"] = "Message should be at least 10 characters long";
    }

    setErrors(errors);
    setIsFormValid(formIsValid);
    return formIsValid;
  }

  const ContactSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setmsgSent('Sending...');
      setmailstatus('Sending...');

      axios.post('/test', { name: ContactName, email: ContactEmail, message: ContactMessage })
        .then(function (response) {
          setmsgSent(' ');
          setmailstatus('Thank you for contacting me @' + ContactName + '. I will get back to you as soon as possible!');
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
          setmailstatus('An error occurred. Please contact the developer or email tanzildewon@gmail.com');
        });
    }
  }






  return (
    <div className="App">

      <header className='top-header'>
        <a href='#' className='logo'>
          T <img src={logo} /> nzil
        </a>
        <nav className='main-menu'
          onClick={() => {

            document.querySelector(".main-menu").classList.toggle('active');
            document.querySelector(".mobile-menu").classList.toggle('active');
            document.querySelector("header.top-header").classList.toggle('active');

          }}
        >
          <li><a href='#'>Home</a></li>
          <li><a href='#about'>About</a></li>
          <li><a href='#protfolio'>Protfolio</a></li>
          <li><a href={TanzilDewancv}>CV</a></li>
          <li className='sp-btn'><a href='#hire'>Hire me</a></li>

        </nav>
        <nav className='mobile-menu' onClick={() => {

          document.querySelector(".main-menu").classList.toggle('active');
          document.querySelector(".mobile-menu").classList.toggle('active');
          document.querySelector("header.top-header").classList.toggle('active');

        }}>
          <FontAwesomeIcon icon={faBars} />

        </nav>
      </header>
      <section className='home' style={{ background: `url(` + TanzilDewan + `)` }}>
        <div className='container hometextsection'>

          <h1 className='title'>Hey, <span className='top-color'>I'm Tanzil Dewan</span></h1>
          <h3 className='subtitle top-color'>Building your digital dreams, one line of code at a time.
            Satisfaction guaranteed!</h3>
          <button className='herobtn'><a href='#protfolio'>Protfolio</a></button>
        </div>
      </section>
      <section className='about' id='about'>

        <div className='container '>
          <div className='titleandsubofsec'>
            <h1 className='title'>ABOUT  <span className='top-color'>ME</span></h1>
            <h3 className='sub'>Here you will find more information about me, what I do, and my current skills.</h3>
          </div>

          <div className='contain-2'>
            <div className='left'>
              <h2 className='title'>Let me Introduce myself!
              </h2>
              <p>



                Hello, I'm <span className='red'>Tanzil</span>, an expert in web development with proficiency across a spectrum of essential technologies. From crafting the structural backbone of websites with <span className='top-color'>HTML</span> and enhancing their visual appeal with CSS, to imbuing interactivity and dynamic functionality using <span className='top-color'>JavaScript</span>, I cover the foundational elements of modern web design.<br /><br />

                My expertise extends to advanced frameworks such as <span className='top-color'>React and React Native,</span> enabling me to create highly responsive and intuitive user interfaces for web and mobile applications alike. Additionally, my proficiency in PHP and Laravel empowers me to develop robust backend solutions, seamlessly integrating with frontend technologies to deliver cohesive user experiences.<br /><br />

                With a keen understanding of database management, particularly <span className='top-color'>MySQL,</span> I ensure efficient data storage and retrieval mechanisms to support the scalability and performance of web applications. Furthermore, my proficiency in WordPress allows me to leverage its versatile platform to build customizable, feature-rich websites tailored to diverse client requirements.<br /><br />

                Driven by a passion for innovation and a commitment to excellence, I bring over <span className='top-color'>5 years</span> of hands-on experience to every project, ensuring the highest standards of quality and reliability. Whether you're envisioning a personal blog, a dynamic e-commerce platform, or a sophisticated web application, I possess the skills and expertise to turn your ideas into reality. Let's collaborate and bring your digital aspirations to lifeHello, I'm Tanzil, an expert in web development with proficiency across a spectrum of essential technologies. From crafting the structural backbone of websites with HTML and enhancing their visual appeal with CSS, to imbuing interactivity and dynamic functionality using JavaScript, I cover the foundational elements of modern web design.<br /><br />

                My expertise extends to advanced frameworks such as React and React Native, enabling me to create highly responsive and intuitive user interfaces for web and mobile applications alike. Additionally, my proficiency in <span className='top-color'>PHP and Laravel</span> empowers me to develop robust backend solutions, seamlessly integrating with frontend technologies to deliver cohesive user experiences.<br /><br />

                With a keen understanding of database management, particularly MySQL, I ensure efficient data storage and retrieval mechanisms to support the scalability and performance of web applications. Furthermore, my proficiency in WordPress allows me to leverage its versatile platform to build customizable, feature-rich websites tailored to diverse client requirements.<br /><br />

                Driven by a passion for innovation and a commitment to excellence, I bring over <span className='top-color'>  <AgeCalculator birthDate={Yearofexpericen} /> </span> of hands-on experience to every project, ensuring the highest standards of quality and reliability. Whether you're envisioning a personal blog, a dynamic <span className='top-color'>e-commerce</span> platform, or a sophisticated web application, I possess the skills and expertise to turn your ideas into reality. Let's collaborate and bring your digital aspirations to life!<br />

              </p>
            </div>
            <div className='right'>
              <h2 className='title'>My Skills</h2>
              <div className='listofskills'>
                <li className='skill'>
                  <label className='title'>HTML, CSS, JS and PHP</label>
                  <label className='persentase' style={{ left: `${progressHtml >= 90 ? progressHtml - 8 : progressHtml}%` }}>{progressHtml}%</label>
                  <div className='progress-ber'>
                    <div className='progress' style={{ width: `${progressHtml}%` }}></div>
                  </div>
                </li>
                <li className='skill'>
                  <label className='title'>WordPress, WooCommerce</label>
                  <label className='persentase' style={{ left: `${progressJs >= 90 ? progressJs - 8 : progressJs}%` }}>{progressJs}%</label>
                  <div className='progress-ber'>
                    <div className='progress' style={{ width: `${progressJs}%` }}></div>
                  </div>
                </li>
                <li className='skill'>
                  <label className='title'>React, React Native, Next JS</label>
                  <label className='persentase' style={{ left: `${progressPhp >= 90 ? progressPhp - 8 : progressPhp}%` }}>{progressPhp}%</label>
                  <div className='progress-ber'>
                    <div className='progress' style={{ width: `${progressPhp}%` }}></div>
                  </div>
                </li>
                <li className='skill'>
                  <label className='title'> Laravel, Python, MYSQL & OTHER DATABASE</label>
                  <label className='persentase' style={{ left: `${progressMysql >= 90 ? progressMysql - 8 : progressMysql}%` }}>{progressMysql}%</label>
                  <div className='progress-ber'>
                    <div className='progress' style={{ width: `${progressMysql}%` }}></div>
                  </div>
                </li>
                <li className='skill'>
                  <label className='title'>Git, CL/CD, AWS, Jira </label>
                  <label className='persentase' style={{ left: `${progressWordpress >= 90 ? progressWordpress - 8 : progressWordpress}%` }}>{progressWordpress}%</label>
                  <div className='progress-ber'>
                    <div className='progress' style={{ width: `${progressWordpress}%` }}></div>
                  </div>
                </li>

                <li className='skill'>
                  <label className='title'>WordPress, WooCommerce </label>
                  <label className='persentase' style={{ left: `${progressWordpressd >= 90 ? progressWordpressd - 8 : progressWordpressd}%` }}>{progressWordpressd}%</label>
                  <div className='progress-ber'>
                    <div className='progress' style={{ width: `${progressWordpressd}%` }}></div>
                  </div>
                </li>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='profolio' id='protfolio'>
        <div className='container'>
          <div className='titleandsubofsec'>
            <h1 className='title'>MY <span className='top-color'>PORTFOLIO</span></h1>
            <h3 className='sub'>Browse through my portfolio by using filters given below.</h3>
          </div>
          <nav className='listofctg'>
            <li data-active='realestate' onClick={activeTab}>Real Estate</li>
            <li data-active='ecommarce' onClick={activeTab} className='active'>E-commerce</li>
            <li data-active='business' onClick={activeTab} >Business</li>
            <li data-active='educational' onClick={activeTab}>Educational</li>
            <li data-active='health-sports' onClick={activeTab}>Sports And Games</li>
          </nav>

          <div className='products' data-active="realestate">
            <div className='product'>
              <div className='img'>
                <img src={process.env.PUBLIC_URL + '/images/realstate.png'} />
              </div>
              <div className='details'>
                <div className='more'>
                  <div className='title'>A Real estate Service</div>
                  <div className='sub'>Find Your property From there</div>
                  <button className='herobtn'><a href='#hire'>Contact Me</a></button>
                </div>
              </div>
            </div>
          </div>
          <div className='products active' data-active="ecommarce">
            <div className='product'>
              <div className='img'>
                <img src={process.env.PUBLIC_URL + '/images/expert-ecommerce-website-development-with-react-and-laravel.jpg'} />
              </div>
              <div className='details'>
                <div className='more'>
                  <div className='title'>A Store </div>
                  <div className='sub'>Sale the daily useing products </div>
                  <button className='herobtn'><a href='#hire'>Contact Me</a></button>
                </div>
              </div>
            </div>
            <div className='product'>
              <div className='img'>
                <img src={process.env.PUBLIC_URL + '/images/turn-your-design-to-web-in-3-days-UX,-UI,-figma,-PSD,-PDF-to-interactive-FRONTEND-DESIGNLANDING-PAGEWEB-PROTOTYPERAPID-DEVUX,UI-BUILD.jpg'} />
              </div>
              <div className='details'>
                <div className='more'>
                  <div className='title'>E-commerce Dashboard </div>
                  <div className='sub'>Dashboard of User</div>
                  <button className='herobtn'><a href='#hire'>Contact Me</a></button>
                </div>
              </div>
            </div>
            <div className='product'>
              <div className='img'>
                <img src={process.env.PUBLIC_URL + '/images/screencapture-demo-kalles-4-3-myshopify-2024-03-29-21_48_05.png'} />
              </div>
              <div className='details'>
                <div className='more'>
                  <div className='title'>Dog Foods And Goods Shop</div>
                  <div className='sub'>Sale Dog Food and more other need product in Online</div>
                  <button className='herobtn'><a href='#hire'>Contact Me</a></button>
                </div>
              </div>
            </div>
            <div className='product'>
              <div className='img'>
                <img src={process.env.PUBLIC_URL + '/images/screencapture-new-ella-demo-myshopify-2024-03-29-21_47_50.png'} />
              </div>
              <div className='details'>
                <div className='more'>
                  <div className='title'>Gadgets Sale Shop</div>
                  <div className='sub'>Gadget item sale in online</div>
                  <button className='herobtn'><a href='#hire'>Contact Me</a></button>
                </div>
              </div>
            </div>
          </div>
          <div className='products' data-active="business">
            <div className='product'>
              <div className='img'>
                <img src={process.env.PUBLIC_URL + '/images/resturants-web.jpg'} />
              </div>
              <div className='details'>
                <div className='more'>
                  <div className='title'>Resturent and Trip Service</div>
                  <div className='sub'>The Web Protal give Resturent and trips service </div>
                  <button className='herobtn'><a href='#hire'>Contact Me</a></button>
                </div>
              </div>
            </div>
          </div>
          <div className='products' data-active="educational">
            <div className='product'>
              <div className='img'>
                <img src={process.env.PUBLIC_URL + '/images/apustutors.jpg'} />
              </div>
              <div className='details'>
                <div className='more'>
                  <div className='title'>Tutor service protal</div>
                  <div className='sub'>Web Application for who give the tutor Service </div>
                  <button className='herobtn'><a href='#hire'>Contact Me</a></button>
                </div>
              </div>
            </div>
          </div>
          <div className='products' data-active="health-sports">
            <div className='product'>
              <div className='img'>
                <img src={process.env.PUBLIC_URL + '/images/Gaimg.jpg'} />
              </div>
              <div className='details'>
                <div className='more'>
                  <div className='title'>WebPlay</div>
                  <div className='sub'>Where your game play take center stage</div>
                  <button className='herobtn'><a href='#hire'>Contact Me</a></button>
                </div>
              </div>
            </div>
            <div className='product'>
              <div className='img'>
                <img src={process.env.PUBLIC_URL + '/images/Sports.jpg'} />
              </div>
              <div className='details'>
                <div className='more'>
                  <div className='title'>PVA</div>
                  <div className='sub'>The Biggest Supporters Group for the maxican national team</div>
                  <button className='herobtn'><a href='#hire'>Contact Me</a></button>
                </div>
              </div>
            </div>
          </div>



          {/* <div className='morebtn'>
            <button className='loadmoerbtn'>Load More</button>
          </div> */}
        </div>
      </section>

      <section className='footer' id='hire'>
        <div className='container'>
          <div className='top'>

            <h1>Customer Reviews</h1>

            <ReviewSlider />


          </div>
          <div className='bottom'>
            <div className='contact'>

              <div className='staps active' data-active='step1'>
                <div className='left'>Start a Project</div>
                <div className='center'>Want to create something amazing?
                  I’m just a message away.</div>
                <div className='right'>
                  <button data-active='step2' onClick={activeContact} >LETS DO THIS</button>
                </div>
              </div>
              <div className='staps ' data-active='step2'>
                <form onSubmit={ContactSubmit}>
                  <div className='A'>
                    <input
                      type='text'
                      placeholder='Name'
                      onChange={(e) => { setContactName(e.target.value) }}
                      value={ContactName}
                      onBlur={validateForm}
                    />
                    {errors.name && <span className="error">{errors.name}</span>}
                    <input
                      type='text'
                      placeholder='Email'
                      onChange={(e) => { setContactEmail(e.target.value) }}
                      value={ContactEmail}
                      onBlur={validateForm}
                    />
                    {errors.email && <span className="error">{errors.email}</span>}
                  </div>
                  <div className='B'>
                    <textarea
                      placeholder='Message'
                      onChange={(e) => { setContactMessage(e.target.value) }}
                      value={ContactMessage}
                      onBlur={validateForm}
                    >{ContactMessage}</textarea>
                    {errors.message && <span className="error">{errors.message}</span>}
                    <div className="validation-status">
                  { isFormValid === 'load'? '': isFormValid ? (
                    <p className="valid">All fields are valid</p>
                  ) : (
                    <p className="invalid">Please correct the errors in the form</p>
                  )}
                </div>
                  </div>
                  <div className='C'>
                    <input
                      type='submit'
                      value={msgSent ? msgSent : "Send message"}
                      data-active='step3'
                      onClick={activeContact}
                      disabled={!isFormValid}
                    />
                  </div>
                </form>
                

              </div>
              <div className='staps ' data-active='step3'>
                <p>{mailstatus}</p>
              </div>

            </div>
            <div className='middle-footer'>
              <div className='left'>
                <h3>TANZIL DEWAN</h3>
                <p>Full stack web developer | Building your digital dreams, one line of code at a time.
                  Satisfaction guaranteed!</p>
              </div>
              <div className='right'>
                <h3>SOCIAL LINKS</h3>

                <ul>
                  <li><a href='https://wa.me/message/3RMWPWKUGZKDN1'><FontAwesomeIcon icon={faWhatsapp} /></a></li>
                  <li><a href='https://www.facebook.com/im.tanzildewan'><FontAwesomeIcon icon={faFacebookF} /></a></li>
                  <li><a href='https://www.instagram.com/tanzildewan/'><FontAwesomeIcon icon={faInstagram} /></a></li>
                  <li><a href='https://www.linkedin.com/in/tanzil-dewan/'><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                  <li><a href='https://twitter.com/tanzildewan'><FontAwesomeIcon icon={faTwitter} /></a></li>
                  <li><a href='mailto:dev.tanzil@gmail.com'><FontAwesomeIcon icon={faEnvelope} /></a></li>


                </ul>
              </div>
            </div>
            <div className='devider'></div>
            <div className='copyright'>
              <p>© All rights reserved 2024. Designed by <a href='#'>Tanzil dewan</a></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
